const slugify = require("gatsby-parallelpublicworks/src/utils/slugify");

module.exports = function getVariantSlug(attributes, title = false) {
  let sep = title ? " " : "-";
  let slug = `${attributes["field_gene"].attributes["title"]}${sep}${attributes["field_c"]}`;
  if (
    attributes["field_clinvar_num"] &&
    `${attributes["field_clinvar_num"]}`.length > 0
  ) {
    slug += `${sep}${attributes["field_clinvar_num"]}`;
  }

  return title ? slug : slugify(slug);
};
