import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { Chip } from "@material-ui/core";
import { OpenInNew as LinkIcon } from "@material-ui/icons";
import getVariants from "../utils/getVariants";
import getVariantSlug from "../utils/getVariantSlug";
import { SiteContext } from "gatsby-parallelpublicworks/src/layouts/Main";
import { Link } from "gatsby";

const columns = [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "variant",
    headerName: "Variant",
    width: 400,
    editable: true,
    renderCell: (params) => {
      const { attributes } = params.value;
      const slug = getVariantSlug(attributes);
      const title = getVariantSlug(attributes, true);
      return (
        <div>
          {slug ? (
            <Link to={`/variants/${slug}`} style={{ color: "#ff6c0d" }}>
              {title}
            </Link>
          ) : (
            <span>{title}</span>
          )}
        </div>
      );
    },
  },
  {
    field: "gene",
    headerName: "Gene",
    width: 150,
    editable: true,
  },
  {
    field: "field_c",
    headerName: "Coding Change (c.)",
    width: 200,
    editable: true,
  },
  {
    field: "field_p",
    headerName: "Protein Change (p.)",
    width: 150,
    editable: true,
  },
  // {
  //   field: "field_clinvar_num",
  //   headerName: "ClinVar Number",
  //   width: 180,
  //   editable: true,
  // },
  {
    field: "field_forum_url",
    headerName: "Variant Forum",
    width: 180,
    editable: true,
    renderCell: (params) => {
      return (
        <div>
          <Chip
            size="small"
            label="Link to forum"
            clickable
            onDelete={() => {}}
            deleteIcon={<LinkIcon />}
            onClick={() => {
              window.open(params.value, "_blank");
            }}
          />
        </div>
      );
    },
  },
  {
    field: "field_public_notes",
    headerName: "Notes",
    width: 225,
    editable: true,
    renderCell: (params) => {
      return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
    },
  },
  {
    field: "field_other_names",
    headerName: "Other Names",
    width: 225,
    editable: true,
    renderCell: (params) => {
      return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
    },
  },
];

function VariantsTable({ urlQuery }) {
  const { drupalUrl } = React.useContext(SiteContext);
  const [variants, setVariants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [paginationOptions, setPaginationOptions] = React.useState({
    currentPage: 0,
    pageSize: 50,
  });

  useEffect(async () => {
    setLoading(true);

    if (urlQuery) {
      urlQuery = `?${urlQuery}&page[limit]=${paginationOptions.pageSize}`;
    } else {
      urlQuery = `?page[limit]=${paginationOptions.pageSize}`;
    }

    urlQuery = urlQuery.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, "");

    let response = await getVariants(drupalUrl, urlQuery);
    setPaginationOptions({
      ...paginationOptions,
      currentPage: 0,
      count: response.meta.count,
      links: response.links,
    });
    setVariants(mapData(response));
    setLoading(false);
  }, [urlQuery]);

  const onPageChange = async (page) => {
    let urlPagination = paginationOptions.links.self;

    if (page > paginationOptions.currentPage)
      urlPagination = paginationOptions.links.next;
    else urlPagination = paginationOptions.links.prev;

    setPaginationOptions({ ...paginationOptions, currentPage: page });

    setLoading(true);
    let response = await getVariants(urlPagination.href, ``, true);
    setPaginationOptions({
      ...paginationOptions,
      currentPage: page,
      count: response.meta.count,
      links: response.links,
    });
    setVariants(mapData(response));
    setLoading(false);
  };

  const mapData = (response) => {
    let { data, included } = response;

    return data.map((variant) => {
      let gene = included.find(
        (item) => variant.relationships?.field_gene?.data?.id === item.id
      );

      return {
        id: variant.id,
        variant: { ...variant, attributes: { ...variant.attributes, field_gene: gene } },
        gene: gene.attributes.title,
        field_c: variant.attributes.field_c,
        field_p: variant.attributes.field_p,
        field_clinvar: variant.attributes.field_clinvar,
        field_clinvar_num: variant.attributes.field_clinvar_num,
        field_forum_url: variant.attributes?.field_forum_url?.uri,
        field_other_names: variant.attributes?.field_other_names?.join(", "),
        field_public_notes: variant.attributes?.field_public_notes?.value,
      };
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={variants}
        columns={columns}
        pageSize={paginationOptions.pageSize}
        paginationMode="server"
        rowCount={+(paginationOptions?.count || 0)}
        onPageChange={onPageChange}
        loading={loading}
        autoHeight
      />
    </div>
  );
}

VariantsTable.propTypes = {
  urlQuery: PropTypes.string,
};

export default VariantsTable;
