import React, { useState } from "react";
import { Typography, Paper, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SearchVariantInput from "../components/SearchVariantInput";
import VariantsTable from "../components/VariantsTable";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: `1rem auto 1rem auto`,
    padding: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(2, 1),
  },
}));

function Variants() {
  const [urlQuery, setUrlQuery] = useState();

  const classes = useStyles();

  return (
    <div className={classes.content}>
      <SearchVariantInput onSearch={setUrlQuery} />
      <Paper component="div" className={classes.tableContainer}>
        <Typography variant="h3">
          Index of Variant-Specific Forums and Other Platforms
        </Typography>
        <VariantsTable urlQuery={urlQuery} />
      </Paper>
    </div>
  );
}

export default Variants;
