import React from "react";
import {
  Paper,
  InputBase,
  Divider,
  IconButton,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Search as SearchIcon,
  Directions as DirectionsIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 800,
    margin: "auto",
    marginTop: theme.spacing(4),
    padding: "2px 4px",
  },
  searchInput: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 2,
  },
  formControl: {
    padding: 10,
    flex: 0.75,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const columns = [
  {
    name: "field_gene.title",
    label: "Gene",
  },
  {
    name: "field_c",
    label: "Coding Change (c.)",
  },
  {
    name: "field_p",
    label: "Protein Change (p.)",
  },
  {
    name: "field_clinvar",
    label: "ClinVar Name",
  },
  {
    name: "field_clinvar_num",
    label: "ClinVar Number",
  },
  {
    name: "field_forum_url.uri",
    label: "Primary group",
  },
  {
    name: "field_public_notes.value",
    label: "Notes",
  },
  {
    name: "field_other_names",
    label: "Other Names",
    isMultiple: true,
  },
];

function SearchVariantInput({ loading, onSearch }) {
  const classes = useStyles();

  const [searchValue, setSearchValue] = React.useState("");
  const [columnsFilter, setColumnsFilter] = React.useState(
    columns.map((col) => col.name)
  );
  const [errorMessage, setErrorMessage] = React.useState();

  const handleChange = (event) => {
    if (event.target.value.length == 0) {
      setErrorMessage("You need to select at least one column");
    } else {
      setErrorMessage();
    }
    setColumnsFilter(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    let query = "filter[query-group][group][conjunction]=OR";
    columnsFilter.forEach((col, index) => {
      query += `\n&filter[col_${index}-filter][condition][path]=${col}
      &filter[col_${index}-filter][condition][operator]=CONTAINS
      &filter[col_${index}-filter][condition][value]=${searchValue}
      &filter[col_${index}-filter][condition][memberOf]=query-group`;
    });

    onSearch(query);
  };

  const clearAndResetSearch = (e) => {
    e.preventDefault();
    setSearchValue("");
    setColumnsFilter(columns.map((col) => col.name));
    onSearch();
  };

  return (
    <Paper component="form" className={classes.container} onSubmit={onSubmit}>
      <div className={classes.searchInput}>
        {/* <IconButton className={classes.iconButton} aria-label="menu">
          <SearchIcon />
        </IconButton> */}
        <InputBase
          className={classes.input}
          placeholder="Search variant"
          inputProps={{ "aria-label": "search variant" }}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          endAdornment={
            <IconButton type="button" aria-label="clear" onClick={clearAndResetSearch}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          disabled={errorMessage}
        >
          <SearchIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        {/* <IconButton color="primary" className={classes.iconButton} aria-label="directions">
          <DirectionsIcon />
        </IconButton> */}
        <FormControl className={classes.formControl}>
          <InputLabel id="multiple-include">Include</InputLabel>
          <Select
            labelId="multiple-include"
            id="mutiple-include"
            variant="filled"
            multiple
            value={columnsFilter}
            onChange={handleChange}
            input={<Input />}
            renderValue={(selected) =>
              columns
                .filter((col) => selected.indexOf(col.name) > -1)
                .map((col) => col.label)
                .join(", ")
            }
            MenuProps={MenuProps}
          >
            {columns.map((colFilter) => (
              <MenuItem key={colFilter.name} value={colFilter.name}>
                <Checkbox
                  checked={
                    columnsFilter.findIndex((column) => column == colFilter.name) > -1
                  }
                />
                <ListItemText primary={colFilter.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Paper>
  );
}

SearchVariantInput.propTypes = {};

export default SearchVariantInput;
