export default function getVariants(drupalUrl, queryParams, isPagination) {
  return new Promise(function (resolve, reject) {
    let userPass = `${process.env.BASIC_REST_USERNAME}:${process.env.BASIC_REST_PASSWORD}`;
    const creds = btoa(userPass);

    const url = `${drupalUrl}/jsonapi/node/variant/${
      queryParams ? queryParams + "&include=field_gene" : "?include=field_gene"
    }`;

    fetch(isPagination ? drupalUrl : url, {
      method: "GET",
      headers: {
        Accept: "application/vnd.api+json",
        Authorization: `Basic ${creds}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error("response", response);
          reject(`${response.status} - ${response.statusText}`);
        }
      })
      .then((response) => {
        if (response) {
          resolve(response);
        } else {
          reject("Something went wrong");
        }
      })
      .catch(function (err) {
        reject(err.message);
      });
  });
}
